.navbar {
    max-width: 1650px;
    position: relative;
    margin: 0 auto;
    padding: 0;
    height: 88px;
   }

   .active svg {
    color: #6ff7ca;
   }

.hamburger-react {
    position: absolute !important;
    vertical-align: middle;
    left: 22px;
    top: 22px;
}
.show-score-inner {
    height: 100%;
    margin-left: 64px;
}
.site-links {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-left: 64px;
}

.nav-icons {
    padding-left: 42px;
    color: rgb(81, 160, 134);
    font-size: 40px;
    
  }

  @media (max-width: 490px) {

    .hamburger-react {
        position: absolute !important;
        vertical-align: middle;
        left: 0;
        top: 22px;
    }
    .show-score {
        padding-left: 0;
    }
}