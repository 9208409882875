.popup {
    position: fixed;
    display: flex;
    z-index: 1;
    justify-content: center;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;

}
.video-player {
    z-index: 15;
    position: relative;
    max-width: 1600px;
    width: 80%;
}
video {
    z-index: 15;
}
.video-overlay {
    display: hidden;
    position: absolute;
    justify-content: center;
    display: flex;
    /* top: 0; */
    /* left: 0; */
    z-index: -33;
    width: 100%;
    height: 100%;
}
video.novid::-webkit-media-controls-current-time-display{ display: none;}
video.novid::-webkit-media-controls-time-remaining-display{display: none;}

video::-webkit-media-controls-play-button {
    color: aquamarine;
    font-size: 5em;
}

.video-player .close-btn {
    z-index: 17;
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 3vw;
    color: #c9d1d9;
}
.video-controls {
    z-index: 38;
    width: 100%;
    position: absolute;
    display: flex;
    font-size: 3em;
    padding-bottom: 1.5em;
    bottom: 0;
    justify-content: space-around;
    align-items: center;
}
.playpause {
    margin-left: -15%;
}
.pause-btn
{
    padding-left: 1em;
}
svg.svg-inline--fa.fa-volume-xmark.volume-btn {
    margin-left: 25%;
}

@media (max-width: 420px) {

    .video-player .close-btn {
        font-size: 2em;
    }
}