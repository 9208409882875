body {
 color: #c9d1d9;
  background-color: #0d1117;
  background-repeat: no-repeat;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.singlegame {
  position: relative;
}
.starttime{
  font-size: 0.8em;

  position: absolute;
  top: -0.6em;
  right: 1em;

}
.react-icons {
  cursor: pointer;
  vertical-align: middle;
}
.team-and-link{
  display: flex;
  align-items: center;
  justify-content: center;
}
.singledategame{
  flex-wrap: wrap;
    display: flex;
    justify-content: center;
}
.allteams {
  padding: 4rem;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(180px, 1fr) );
  justify-items: center;
}
.team {
  text-align: center;
  padding: 1em;
  max-width: fit-content;
  align-items: center;
  margin-bottom: 1em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.home p.team-score, .away p.team-score {
  font-size: 1.17em;
  color: #c9d1d9;
  font-weight: lighter;
  text-align: right;
  margin-right: 1em;
}
.home p.team-score.winning, .away p.team-score.winning {
  font-size: 1.17em;
  color: #dae0e7;
  font-weight: bold;
}
.game-day {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.allgames-team-spesific{

  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

}

.singlegame {
  
  margin: 1em;
  position: relative;
  background-color: #161b22;
  padding: 1em;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  margin-bottom: 1em;

}
span.goal-time {
  padding-right: 4px;
}
.recaps {
  display: flex;
  flex-direction: row;
  padding: 0px 1em 2em 0;
}
span.recap{
  margin-right: 0.7em;
}
.recap_link {
  cursor: pointer;
  margin-right: 0.9em;
}
.hidden {
  display: none;
}
.Highlight {
  color: aquamarine;
}
.curr-score {
  margin-left: 1em;
}
.goals {
  display: grid;
  grid-template-columns: fit-content(250px) 50px;
  grid-auto-rows: minmax(37px, auto);
  grid-gap: 10px;
  justify-content: left;
}
.goal {
  font-size: 0.7em;
  padding-bottom: 3px;
  margin: 2px;
}
.vid_link {
  padding-left: 1em;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.allgames {
  margin: 0 auto;
  max-width: 1650px;
}

.score {
  margin: 1em;
  font-weight: bold;
  top: 3px;
  right: 0px;
  position: absolute;
}
.checked {
  color: aquamarine;
}
span.fav {
  margin-left: -0.5em;
  padding-right: 5px;
}
img.team-image {
  width: 5em;
}
.home, .away {
  font-weight: bold;
  padding: 0 0 1em 0;
  display: grid;
  grid-template-columns: 1em 6em 9em 5em;
  align-items: center;
  
}
.minified {
  max-width: 320px;
}

@media (max-width: 720px) {
  .singlegame-game {
    
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .singlegame {
    max-width: 450px;
  }
  .home, .away {
    grid-template-columns: 1em 6em 5em 4em;
    font-size: 1em;

  }
  img.team-image {
    width: 4em;
}

.goal {
    max-width: 90%;
  }

}

.show-score{
  padding-left: 1em;
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.show-highlight {
  padding-left: 1em;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.controls {
  align-items: center;

  display: flex;
  justify-content: center;
  max-width: 500px;
}
.show-score-inner {
  display: flex;
}

.show-highlight img {
  margin-top: -9px;
}

@media (max-width: 670px) {
.allteams {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(162px, 1fr) );
  justify-items: center;
}
}
@media (max-width: 420px) {
  
  .controls {
    transform: scale(0.9);
  }
  .singlegame {
    grid-template-columns: 1em 6em 5em 3em;

    max-width: 70%;
  }
  .recaps {
    font-size: 0.7em;
  }
  .goals {
    font-size: 0.7em;
  }
  p.team-name {
    font-size: 0.8em;
  }
  a {
    text-decoration: none;
    color: rgb(201, 209, 217);
  }

}

@media (max-width: 390px) {

}
/* SLIDER SHIT */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0.7);
  background-color: #282b30;
  -webkit-transition: .4s;
  transition: .4s;
}


.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(81, 160, 134);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(81, 160, 134);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  color: #c9d1d9;
  border-radius: 34px;
}

.slider.round:before {
  color: #c9d1d9;
  border-radius: 50%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 20%;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}